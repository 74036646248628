import { useState } from 'react';
import { DefaultToast } from 'react-toast-notifications';

export const DetailedErrorContent = ({ message, details }) => {
  const [showDetails, setShowDetails] = useState(false);

  const onShowDetails = evt => {
    evt.preventDefault();
    setShowDetails(!showDetails);
  };

  return (
    <div>
      <span>{message}</span>
      <p>
        <a style={{ color: '#d89f9a', fontSize: 'smaller' }} onClick={onShowDetails}>
          {showDetails ? <i class="far fa-angle-up" /> : <i class="far fa-angle-left" />}&nbsp;Show
          details
        </a>
      </p>
      {showDetails ? <p style={{ fontSize: 'smaller', color: '#ac867d' }}>{details}</p> : ''}
    </div>
  );
};

export default ({ children, ...props }) => {
  const { appearance } = props;
  return (
    <div
      onTouchEnd={evt => {
        evt.preventDefault();
        props.onDismiss();
      }}
    >
      <DefaultToast {...props}>{children}</DefaultToast>
    </div>
  );
};
