import Section from 'components/layout/Section';
import Title from 'components/label/Title';
import FormContainer from 'components/layout/FormContainer';
import Head from 'next/head';

// NOTE: using a local navbar here because the real NavBar component has
// HoC dependencies
const NavBar = ({ onStartOver }) => {
  return (
    <div>
      <div className="sticky">
        <nav className="navbar is-white" role="navigation" aria-label="main navigation">
          <div className="navbar-brand">
            <a
              className="navbar-item"
              href="https://coveinsurance.co.nz"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img src="/static/icons/cove-insurance.png" alt="Cove Insurance" />
            </a>
          </div>
          <div className="navbar-menu is-uppercase is-shadowless">
            <div className="navbar-end">
              <a className="navbar-item has-text-primary" href="/quote/product">
                <i className="fal fa-undo" />
                <p className="isDesktop-navbar navbar-choice">&nbsp;Start Over</p>
                <p className="isMobile-navbar navbar-choice">&nbsp;</p>
              </a>
            </div>
          </div>
        </nav>
      </div>
      <style jsx="true">{`
        .navbar-item {
          display: flex;
          align-items: center;
        }
        .estimate-premium {
          position: absolute;
          display: block;
          margin-top: 6pt;
          margin-left: auto;
          margin-right: auto;
          left: 0;
          right: 0;
          text-align: center;
        }

        .estimate-premium span {
          font-size: 0.8rem;
          color: #6a6a6a;
        }

        .estimate-premium span span {
          font-size: 1.3rem;
          color: #128e8b;
          position: relative;
          top: 0.05em;
        }
        .isMobile-navbar {
          display: none;
        }

        @media screen and (max-width: 480px) {
          .isDesktop-navbar {
            display: none;
          }
          .isMobile-navbar {
            display: initial;
          }
          .estimate-premium {
            margin-left: auto;
            margin-right: auto;
            padding-left: 15%;
          }

          .estimate-premium span span {
            font-size: 1.3rem;
            color: #128e8b;
            position: relative;
            top: 0.05em;
          }
        }

        .sticky {
          position: fixed;
          top: 0;
          width: 100%;
          z-index: 9999999 !important ;
        }

        .navbar {
          height: 65px;
          box-shadow: 0 1px 2.5px 0 #cecece;
        }

        .navbar,
        .navbar-menu,
        .navbar-start,
        .navbar-end {
          align-items: stretch;
          display: flex;
          padding: 0;
          font-size: 14px;
        }

        .navbar-choice {
          font-size: 14px;
        }
      `}</style>
    </div>
  );
};

const ErrorPage = () => {
  return (
    <>
      <Head>
        <title>Error | Cove</title>
      </Head>
      <NavBar />
      <Section>
        <FormContainer>
          <Title marginTop="90px">
            Something went wrong. <a href="/quote/product">Please start over.</a>
          </Title>
        </FormContainer>
      </Section>
    </>
  );
};

export default ErrorPage;
