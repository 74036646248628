import * as Sentry from '@sentry/browser';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
// Global Style
import 'rc-slider/assets/index.css';
import 'styles/cove.scss';
import 'styles/animated-tick.scss';

import Amplify from '@aws-amplify/core';

// Import this at _app.js, else things don't work as reliably...
import '@aws-amplify/auth';

import { ToastProvider } from 'react-toast-notifications';

import Head from 'next/head';
import Router from 'next/router';

import * as log from 'loglevel';
// Global Context
import { Context } from 'context';

import App, { Container } from 'next/app';
import React from 'react';

import getConfig from 'next/config';

import ErrorPage from 'components/FullErrorPage';
import CoveToast from 'components/CoveToast';

import { Mixpanel } from 'utilities/mixpanel';

import { Customer as CustomerAPI } from '@covexl/cove-api-client';
import { CustomerProvider } from 'state/Customer.context';

import logger from 'utilities/logger';

const { publicRuntimeConfig } = getConfig();
const stripePromise = loadStripe(publicRuntimeConfig.STRIPE_PUBLIC_KEY);

Amplify.configure({
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: publicRuntimeConfig.USER_POOL_REGION,

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: publicRuntimeConfig.USER_POOL_ID,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: publicRuntimeConfig.USER_POOL_WEB_CLIENT_ID,

    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    authenticationFlowType: publicRuntimeConfig.AUTHENTICATION_FLOW_TYPE
  }
});

Object.defineProperty(Array.prototype, 'flat', {
  value: function(depth = 1) {
    return this.reduce(function(flat, toFlatten) {
      return flat.concat(
        Array.isArray(toFlatten) && depth > 1 ? toFlatten.flat(depth - 1) : toFlatten
      );
    }, []);
  }
});

export default class CoveApp extends App {
  async componentDidMount() {
    if (typeof window !== 'undefined') {
      this.handleMessage = async event => {
        console.log('Message event received:', event);

        const allowedOrigins = [
          'https://insure.uat.opsies.net.nz',
          'https://insure-logout.uat.opsies.net.nz',
          'https://sharesies.co.nz',
          '*'
        ];

        if (!allowedOrigins.includes(event.origin)) {
          console.log('Message received from an unknown origin:', event.origin);
          console.log('event is:', JSON.stringify(event));
          return;
        }

        if (event.data.sharesies_logout === true) {
          console.log('Logout message received:', event.data.text);
          await Amplify.Auth.signOut();
          window.localStorage.clear();
        }
      };

      window.addEventListener('message', this.handleMessage);
      console.log('Message event listener added');

      // Check if the current window is running inside an iframe
      if (window.self !== window.top) {
        // Add event listener for the DOMContentLoaded event
        window.addEventListener('DOMContentLoaded', () => {
          // Post a message to the parent window
          window.parent.postMessage(
            {
              type: 'connect',
              url: window.location.href
            },
            '*'
          );
        });
      }
    } else {
      console.log('window not available to listen for message');
    }
    logger.log('App', 'ENV', publicRuntimeConfig);
    Sentry.init({
      dsn: publicRuntimeConfig.SENTRY_KEY,
      Environment: publicRuntimeConfig.ENVIRONMENT,
      release: `cove-webapp@${publicRuntimeConfig.VERSION}`,
      attachStacktrace: true
    });
    let user = null;

    try {
      user = await Amplify.Auth.currentAuthenticatedUser();
      logger.log('App', 'User is logged in', { user });
      const customerId = user.attributes['custom:customer_id'];
      logger.log('App', `Customer Id: ${customerId}`);
      if (customerId) {
        Mixpanel.identify(customerId);
      }
      const session = await Amplify.Auth.currentSession();
      const accessToken = session.getAccessToken().getJwtToken();

      localStorage.setItem('cove-access-token', accessToken);
      const state = { loggedIn: true };
      const customerAPI = new CustomerAPI(
        publicRuntimeConfig.API_HOST,
        publicRuntimeConfig.ENVIRONMENT,
        {
          accessToken
        }
      );
      const customer = await customerAPI.retrieve(customerId);
      if (customer && customer.sharesies_account_active) {
        localStorage.setItem('sharesies_account_active', true);
        state.is_sharesies_account_active = true;
      }
      state.firstName = customer.first_name;
      state.lastName = customer.last_name;
      state.gender = customer.gender;

      localStorage.setItem('cove-customer-id', customerId);

      // retrieve the customer
      this.setState(state);
    } catch (e) {
      log.error(e);
      log.error('User is not logged in');
      this.setState({ loggedIn: false });
    }

    logger.log('App', 'Initializing State');
    this.setState({
      answers: JSON.parse(localStorage.getItem('answers') || '{}'),
      risk: JSON.parse(localStorage.getItem('cove-risk') || '{}')
    });

    Sentry.configureScope(scope => {
      let userScope = {};
      if (localStorage && localStorage.getItem('cove-customer-id')) {
        userScope.id = localStorage.getItem('cove-customer-id');
      }

      if (user && user.username) {
        userScope.username = user.username;
      }
      scope.setUser(userScope);
      scope.setExtra('_app State', this.state);
      scope.setExtra('Environment', publicRuntimeConfig.ENVIRONMENT);
      scope.setExtra('Env Name', publicRuntimeConfig.ENV_NAME);
      scope.setExtra('Page', this.props.router.route);
      scope.setExtra(
        'Router Props',
        this.props.router.components[this.props.router.route].pageProps
      );
    });

    Router.events.on('routeChangeComplete', () => {
      window.scrollTo(0, 0);
      var url = window.location.pathname;
      Mixpanel.track('Viewed Page', { Value: url });
    });
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleMessage);
  }

  state = {
    setState: (state, callback) => {
      this.setState(state, callback);
    },
    setStateAsync: state => {
      return new Promise(resolve => this.setState(state, resolve));
    }
  };

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Sentry.configureScope(scope => {
      let userScope = {};
      if (localStorage && localStorage.getItem('cove-customer-id')) {
        userScope.id = localStorage.getItem('cove-customer-id');
      }

      scope.setUser(userScope);
      scope.setExtra('_app State', this.state);
      Sentry.captureException(error, { extra: errorInfo });
    });
  }

  render() {
    const { Component, pageProps } = this.props;
    return (
      <>
        <Head>
          <title>Cove Insurance</title>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
          />
          <meta name="robots" content="noindex" key="_index_block_all" />
          <meta name="googlebot" content="noindex" key="_index_block_google" />
          <meta name="theme-color" content="#128e8b" media="(prefers-color-scheme: light)" />
          <meta name="theme-color" content="#128e8b" media="(prefers-color-scheme: dark)" />
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.min.css"
          />
        </Head>
        {this.state.error ? (
          <ErrorPage />
        ) : (
          <Elements stripe={stripePromise}>
            <ToastProvider placement="bottom-left" components={{ Toast: CoveToast }}>
              <Context.Provider value={this.state}>
                <CustomerProvider>
                  <div style={{ marginTop: '65px', marginBottom: '65px' }}>
                    <Component {...pageProps} resetState={this.resetState} />
                  </div>
                </CustomerProvider>
              </Context.Provider>
            </ToastProvider>
          </Elements>
        )}
      </>
    );
  }
}
